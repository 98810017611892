import styled from "styled-components";

const Disscussion = () => {
  return (
    <Wrapper>
      <h1>Disscussion</h1>
    </Wrapper>
  );
};

export default Disscussion;

const Wrapper = styled.div``;
