import styled from "styled-components"
import Course from "../components/Course";

const Courses = () => {
    return (
        <Wrapper>
            <Course />
            <Course />
            <Course />
            <Course />
            <Course />
        </Wrapper>
    )
}

export default Courses;

const Wrapper = styled.div`

`;