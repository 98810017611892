import styled from "styled-components"
import { useParams } from "react-router-dom"
import { blogs } from "./Blogs"

const BlogDetail = () => {
    const { slug } = useParams()

    return (
        <Wrapper>
            {blogs.filter((blog) => blog.id === Number(slug))
                .map(({ title, paragraph, id }) => {
                    return (
                        <Detail key={id}>
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quibusdam consequuntur tenetur, at, ipsam quasi ad ex porro dolor consequatur voluptas! Repellat quibusdam libero facilis nemo magni in, necessitatibus veniam.
                            </p>

                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quibusdam consequuntur tenetur, at, ipsam quasi ad ex porro dolor consequatur voluptas! Repellat quibusdam libero facilis nemo magni in, necessitatibus veniam.
                            </p>
                        </Detail>
                    )
                })}
        </Wrapper>
    )
}

export default BlogDetail;

const Wrapper = styled.div`

`;

const Detail = styled.div`
    padding: 30px;
    color: white;
    background-color: #2094b1;
    margin: 20px;

    p {
        margin: 20px 0;
    }
`;