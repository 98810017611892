import styled from "styled-components";

const Course = () => {
  return (
    <Wrapper>
      <h1>Course</h1>
    </Wrapper>
  );
};

export default Course;

const Wrapper = styled.div``;
