import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import BlogCreate from "./containers/BlogCreate";
import BlogDetail from "./containers/BlogDetail";
import Blogs from "./containers/Blogs";
import Courses from "./containers/Courses";
import Discussions from "./containers/Discussions";

function App() {
  const routes = [
    { path: "/blogs", element: <Blogs /> },
    { path: "/courses", element: <Courses /> },
    { path: "/discussions", element: <Discussions /> },
    { path: "/blogs/:slug", element: <BlogDetail /> },
    { path: "/blogs/create", element: <BlogCreate /> },
  ]

  return (
    <AppContainer>
      <Sidebar />
      <Content>
        <Routes>
          {routes.map(({ path, element }) => <Route path={path} element={element} />)}
        </Routes>
      </Content>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  min-height: calc(100vh - 30px);
  background-color: #1D1F21;

  display: grid;
  grid-template-columns: 263px 1fr;
  gap: 30px;

  padding: 0 150px;
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;