import styled from "styled-components"
import Disscussion from "../components/Disscussion";

const Discussions = () => {
    return (
        <Wrapper>
            <Disscussion />
            <Disscussion />
            <Disscussion />
            <Disscussion />
            <Disscussion />
        </Wrapper>
    )
}

export default Discussions

const Wrapper = styled.div`

`;