import { Link } from "react-router-dom";
import styled from "styled-components";

const Blog = ({ title, paragraph, id }) => {
  return (
    <Wrapper to={`/blogs/${id}`}>
      <h1>{title}</h1>
      <p>{paragraph}</p>
    </Wrapper>
  );
};

export default Blog;

const Wrapper = styled(Link)`
  display: block;
  padding: 30px;
  color: white;
  background-color: #2094b1;
  margin: 20px;
`;
